export function oidcSettings() {
  const { oauthIssuer, oauthClientId, ssoCallbackUrl } =
    useRuntimeConfig().public

  return {
    authority: oauthIssuer,
    metadata: {
      issuer: oauthIssuer,
      authorization_endpoint: oauthIssuer + '/as/authorization.oauth2',
      token_endpoint: oauthIssuer + '/as/token.oauth2',
      userinfo_endpoint: oauthIssuer + '/idp/userinfo.openid',
      end_session_endpoint: oauthIssuer + '/idp/startSLO.ping'
    },
    clientId: oauthClientId,
    redirectUri: ssoCallbackUrl,
    client_id: oauthClientId,
    redirect_uri: ssoCallbackUrl,
    responseType: 'code',
    scope: 'openid profile email',
    // Below can be enabled to auto-relog & refresh the session for the user
    automaticSilentRenew: false,
    silentRedirectUri: ssoCallbackUrl
  }
}

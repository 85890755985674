<template>
  <div class="relative">
    <slot name="trigger" :toggle="toggle" :active="active" :uuid="ddId"></slot>
    <button
      v-if="!$slots.trigger && label"
      class="font-bold text-base py-2 inline-flex flex-row items-center space-x-2 shadow-md"
      :aria-expanded="`${active}`"
      :aria-controls="ddId"
      @click="toggle"
    >
      <h5 class="inline text-sm text-gray-700">{{ label }}</h5>
      <Icon
        name="IconChevronDown"
        class="transform transition-all duration-200"
        :class="active ? 'rotate-180' : ''"
        :title="active ? 'hide content' : 'show content'"
      />
    </button>
    <div
      :id="ddId"
      class="bg-white w-full z-10 overflow-y-hidden"
      :class="[
        active ? 'block' : 'hidden',
        fullWidth ? '' : 'md:w-64',
        isPageFlow ? '' : 'absolute'
      ]"
    >
      {{ content }}
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'Dropdown',
  props: {
    label: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    isPageFlow: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    active: false,
    ddId: uuidv4()
  }),
  methods: {
    toggle() {
      this.active = !this.active
    },
    open() {
      this.active = true
    },
    close() {
      if (this.active) {
        this.active = false
      }
    }
  }
}
</script>

import { Loader } from '@googlemaps/js-api-loader'

export default defineNuxtPlugin(() => {
  const getLoader = () => {
    const runtimeConfig = useRuntimeConfig().public
    const additionalOptions = {}
    return new Loader({
      apiKey: runtimeConfig.googleSearchKey,
      version: 'weekly',
      ...additionalOptions
    })
  }

  const getLocation = (
    input: HTMLElement,
    latlong: google.maps.LatLng,
    options: google.maps.MapOptions
  ) => {
    const loader = getLoader()
    loader.load().then(async (google) => {
      const { Map } = (await google.maps.importLibrary(
        'maps'
      )) as google.maps.MapsLibrary
      const map = new Map(input, options)
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary
      const marker = new AdvancedMarkerElement({
        map,
        position: latlong
      })
      return marker
    })
  }

  const makeAutoComplete = (
    input: HTMLInputElement,
    options = { types: ['(cities)'] }
  ) => {
    const loader = getLoader()
    loader.load().then(async (google) => {
      const { Autocomplete } = (await google.maps.importLibrary(
        'places'
      )) as google.maps.PlacesLibrary
      const autocomplete = new Autocomplete(input, options)
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        input.dispatchEvent(new CustomEvent('changed', { detail: place }))
      })
    })
  }

  const reverseGeocode = async (lat: number, lng: number) => {
    const loader = getLoader()
    return await loader.load().then((google) => {
      const geocoder = new google.maps.Geocoder()
      const latlng = new google.maps.LatLng(lat, lng)
      return geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results) {
            return results
          } else {
            console.error('Geocoder failed due to: ' + status)
            return status
          }
        }
      })
    })
  }

  return {
    provide: {
      maps: {
        getLocation,
        makeAutoComplete,
        reverseGeocode
      }
    }
  }
})

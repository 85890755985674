import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '~/config/oidc'
import { useRuntimeConfig } from '#app'

export default defineNuxtPlugin(() => {
  const storeModule = vuexOidcCreateStoreModule(oidcSettings(), {
    namespaced: true,
    dispatchEventsOnWindow: true
  })
  return {
    provide: {
      auth: storeModule
    }
  }
})
